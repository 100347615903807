<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex align-center pr-2 my-2" style="width: 100%">
          <div class="d-inline-flex align-center pl-2" style="width: 50%">
            <v-autocomplete
              v-model="credentialId"
              class="mr-2 custom-search-perm"
              outlined
              dense
              :items="credentials"
              :loading="isLoadingCredentials"
              :search-input.sync="searchCredentials"
              clearable
              hide-no-data
              hide-details
              item-text="name"
              item-value="id"
              :label="$lang.labels.credential"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
            ></v-autocomplete>
            <v-btn
              icon
              light
              color="primary"
              class="ml-1"
              @click="searchCredentialsFunction(searchCredentials)"
            ><v-icon
              dense
              small
            >mdi-refresh</v-icon></v-btn>
          </div>
          <div class="d-inline-flex align-center pl-3" style="width: 50%">
            <v-text-field
              v-model="search"
              v-debounce:500="handleDebounceSearch"
              class="custom-search-perm"
              hide-details
              outlined
              clearable
              dense
              :label="$lang.labels.searchByModuleName"
              data-cy="search-by-name"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          :disabled="!credentialId"
          :data-cy="`hub-edit-${item.id}`"
          color="primary"
          class="mx-0 button-default-width"
          :to="{ name: 'hubEdit', params: { id: item.id }, query: { credentialId: credentialId } }"
        >{{ $lang.actions.open }}</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { getProcessCredentialsUsingGET as getCredentials } from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      isLoadingCredentials: false,
      credentials: [],
      credentialId: '',
      searchCredentials: '',
      hubDetailsData: {},
      showHubDetails: false
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    search: {
      handler(val) {
        if (!val) {
          this.$emit('fetchHub', { options: this.options, search: '', credentialId: this.credentialId })
          this.savePreFill()
        }
      }
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchHub', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', credentialId: this.credentialId })
        this.savePreFill()
      },
      deep: true
    },
    credentialId: {
      handler () {
        if (!this.lock) this.$emit('fetchHub', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', credentialId: this.credentialId })
        const credentialObj = this.credentials.find((x) => x.id === this.credentialId)

        if (this.credentialId && credentialObj) this.searchCredentials = credentialObj.name
        this.savePreFill()
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchHub', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', credentialId: this.credentialId })
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.hubList) {
        this.search = preFill.hubList.search
        this.searchCredentials = preFill.hubList.searchCredentials
        this.credentialId = preFill.hubList.credentialId
        this.options = preFill.hubList.options
      } else {
        this.searchCredentials = 'DEFAULT HUB'
      }
    } else {
      this.searchCredentials = 'DEFAULT HUB'
    }
    this.$emit('fetchHub', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', credentialId: this.credentialId })
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    handleDebounceSearch(val, event) {
      this.search = val
      if (val && val.length > 1 && !this.lock) this.$emit('fetchHub', { options: this.options, search: val, credentialId: this.credentialId })
      if (!this.lock && (val === null || val === '')) {
        this.$emit('fetchHub', { options: this.options, search: '', credentialId: this.credentialId })

        return
      }
      this.savePreFill()
    },
    searchCredentialsFunction(val = '') {
      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = 'FLOWY'

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data.items
          if (res.data.data.items.length === 1) {
            this.credentialId = res.data.data.items[0].id
          }
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.hubList) preFill.hubList = {
          search: '',
          searchCredentials: '',
          credentialId: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.hubList.search = this.search
        preFill.hubList.searchCredentials = this.searchCredentials
        preFill.hubList.credentialId = this.credentialId
        preFill.hubList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
